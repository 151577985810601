<template>
  <b-modal
      :ref="id"
      :id="id"
      :title="title"
      title-class="text-uppercase"
      :modal-class="`modal-${variant}`"
      size="lg"
      centered
      hide-footer
  >
    <validation-observer
        ref="form_validation"
        #default="{invalid}"
    >
      <b-form
          class="mt-2"
          method="POST"
          @submit.prevent="submit"
      >
        <slot name="content"></slot>
        <hr/>
        <div class="d-flex justify-content-end" name="footer-button">
          <b-button
              :variant="variant"
              @click="hide"
              size="sm"
              variant="secondary"
          >
            {{ cancelTitle }}
          </b-button>
          <b-button
              class="ml-1"
              :variant="variant"
              :disabled="invalid"
              size="sm"
              type="submit"
          >
            {{ okTitle }}
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import LoadingButton from "@/views/components/LoadingButton";

export default {
  name: "ModalFormValidation",
  components: {
    ValidationProvider,
    ValidationObserver,
    LoadingButton
  },
  props: {
    id: String,
    title: {
      default () {
        return ''
      }
    },
    okTitle: {
      default () {
        return this.$t('cloudVps.msgBox.button.confirm')
      }
    },
    cancelTitle: {
      default () {
        return this.$t('cloudVps.msgBox.button.cancel')
      }
    },
    variant: {
      default: 'primary'
    },
    okVariant: {
      type: String,
      default: null
    }
  },
  methods: {
    show () {
      this.$refs[this.id].show()
    },
    hide () {
      this.$refs[this.id].hide()
    },
    submit () {
      const self = this
      this.$refs.form_validation.validate().then(success => {
        if (success) {
          self.$emit('submit')
          self.hide()
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
