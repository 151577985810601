<template>
  <b-form-group
      :label="label"
      :label-for="id"
  >
    <validation-provider
        #default="{ errors }"
        :name="name"
        :rules="rules"
    >
      <b-input-group
          :class="errors.length > 0 ? 'is-invalid' : null"
      >
        <b-input-group-prepend is-text v-if="iconPrepend === 'show'">
          <slot name="icon-prepend"></slot>
        </b-input-group-prepend>
        <b-form-input
            :id="id"
            :name="id"
            :value="value"
            :state="errors.length > 0 ? false:null"
            :placeholder="placeholder"
            :type="type"
            @input="updateValue"
        />
        <b-input-group-append is-text v-if="iconAppend === 'show'">
          <slot name="icon-append"></slot>
        </b-input-group-append>
      </b-input-group>
      <section v-for="(error, index) in errors">
        <small class="text-danger">{{ error }}</small>
        <br v-if="index !== (errors.length - 1)"/>
      </section>
    </validation-provider>
  </b-form-group>
</template>

<script>

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'

export default {
  name: "FieldValidation",
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props: {
    id: String,
    name: String,
    label: String,
    type: String,
    rules: String,
    value: [String, Number],
    placeholder: String,
    iconPrepend: {
      type: String,
      default: 'disabled'
    },
    iconAppend: {
      type: String,
      default: 'disabled'
    },
  },
  data () {
    return {
      errors: [],
      required,
      email
    }
  },
  methods: {
    updateValue(value) {
      this.$emit("input", value);
    },
  },
}
</script>
