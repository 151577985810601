<template>
  <div class="progress-wrapper">
    <b-card-text class="mb-0 text-center">
      <slot/>
    </b-card-text>
    <b-progress
      :max="max"
      :variant="proxyBandwidth()['variant']"
      striped
    >
      <b-progress-bar :value="value">
        <span>{{ proxyBandwidth()['percent'] }}%</span>
      </b-progress-bar>
    </b-progress>
  </div>
</template>

<script>
import InputHelper from '@/mixins/utils/inputHelper'

export default {
  name: 'ProcessBar',
  mixins: [InputHelper],
  props: {
    value: {
      type: Number,
      default: 0,
      required: true
    },
    max: {
      type: Number,
      default: 100,
      required: true
    }
  },
  computed: {
    proxyBandwidth () {
      const self = this
      return function () {
        let percent = self.value / self.max * 100
        percent = parseFloat(percent.toFixed(2))
        if (percent < 70) {
          return {
            variant: 'success',
            percent
          }
        } else if (percent < 90) {
          return {
            variant: 'warning',
            percent
          }
        } else {
          return {
            variant: 'danger',
            percent
          }
        }
      }
    }
  }
}
</script>
